.App {
  font-family: sans-serif;
  text-align: center;
}

.signup {
  display: flex;
  flex-direction: column;
  margin: 80px;
}

.signup input,
button {
  margin: 10px;
}

.alert {
  border: 1px solid red;
  padding: 5px;
  background-color: #f67373;
}
